<template>
  <div class="body">
    <div class="flex-class">
      <!-- <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item>
                    <el-input v-model="formInline.number" placeholder="搜索"></el-input>
                </el-form-item>
            </el-form> -->
      <div>
        <el-button type="primary" plain>新建分组</el-button>
        <el-button type="primary" plain>分组排序</el-button>
        <el-button type="primary" @click="createEditClick(undefined, 1)"
          >+创建工单流程图</el-button
        >
      </div>
    </div>
    <div class="home-class">
      <div class="head-class">
        <div class="title-class">工流单管理</div>
      </div>
      <div class="listbox" v-if="showlist.length == 0">暂无工单流程图</div>
      <div v-else>
        <div class="list-class" v-for="(i, index) in showlist" :key="index">
          <div class="line-class-left">
            <div
              class="image-border"
              :style="{ backgroundColor: i.backgroundColor }"
            >
              <img :src="i.logo" alt="" />
            </div>
            <div class="line-left">
              <div class="line-left-title">{{ i.workOrderName }}</div>
              <div class="line-left-text">{{ i.descInfo }}</div>
            </div>
          </div>
          <div class="line-class-content">全员可见</div>
          <div class="line-class-right">
            <i class="el-icon-plus" @click="add(i)" style="color: #fff"></i>
            <img
              src="../../assets/images/yunwei/edit.png"
              @click="createEditClick(i, 2)"
              alt=""
            />
            <img
              src="../../assets/images/yunwei/delete.png"
              @click="deleteClick(i)"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

   <el-dialog title="分配电站" :visible.sync="dialogVisible" width="50%">
      <el-table
        :data="powerStationList"
        border
        @selection-change="handleSelectionChange"
        height="530px"
        ref="multipleTable"
      >
        <el-table-column type="selection" width="55" align="center">
        </el-table-column>
        <el-table-column
          prop="powerStationName"
          label="电站名称"
          align="center"
          show-overflow-tooltip
        >

        <template v-slot="{row}">
          <span  style="color:red" >{{row.powerStationName}}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="companyName"
          label="公司"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          prop="address"
          label="地址"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
      </el-table>
      <!-- <el-pagination
        @size-change="handleSizeChange2"
        @current-change="handleCurrentChange2"
        :current-page="queryInfo2.currPage"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="queryInfo2.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total2"
      >
      </el-pagination> -->
      <el-row type="flex" justify="center" style="margin-top: 10px">
        <el-button type="primary" @click="handleAddSubmit">添加</el-button>
        <el-button @click="dialogVisible = false">取消</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.powerStationCode = localStorage.getItem("powerStationCode");
  },
  mounted() {
    this.getTableList();
  },
  data() {
    return {
      formInline: {},
      showlist: [],
      powerStationCode: "",
      powerStationName: localStorage.getItem("powerStationName"),
      userCode: JSON.parse(localStorage.getItem("userInfo")).userCode,
      dialogVisible: false,
      powerStationList: [],
      submitList: [],
      queryInfo: {
        condition: {},
        currPage: 1,
        pageSize: 100000,
      },
    };
  },
  methods: {
    async getPowerStation() {
      this.queryInfo.condition.userCode = this.userCode;
      const res = await this.$http.post("/powerStation/list", this.queryInfo);
      if (res.data.code === 200) {
        this.total = res.data.data.count;
        // this.queryInfo.currPage = res.data.data.currPage;
        this.powerStationList = res.data.data.data;
      } else {
        this.$message.error(res.data);
      }
    },
    async huixian(list) {
      const res2 = this.powerStationList.filter((item) => {
        console.log(item, list, 139);
        // 使用 some 方法来检查 item.name 是否包含 row.approval 中任何一个元素
        return list.some((ele) => {
          return item.powerStationCode.includes(ele);
        });
      });
      console.log(res2, 692);

      this.$nextTick(() => {
        if (res2) {
          this.$refs.multipleTable.clearSelection();
          res2.forEach((item) => {
            this.$refs.multipleTable.toggleRowSelection(item, true);
          });
        } else {
          this.$refs.multipleTable.clearSelection();
        }
      });
    },
    handleSelectionChange(val) {
      this.submitList = val;
    },
    async add(row) {
      await this.getPowerStation();
      this.id = row.id;
      console.log(row,'rowwwwwwwwwwwssssssss')
      this.selectList = row.powerStationCode.split(",");

      this.dialogVisible = true;
      this.huixian(this.selectList);
    },
    async handleAddSubmit() {
      const res = await this.$http.post("WorkOrder/updateWork", {
        id: this.id,
        powerStationCode: this.submitList
          .map((item) => item.powerStationCode)
          .join(","),
      });
      if (res.data.code === 200) {
        this.dialogVisible = false;
        this.$message.success("分配成功");
        this.getTableList();
      } else {
        this.$message.error(res.data.message);
      }
    },
    // 查询
    onSubmit() {},
    createEditClick(listItem, index) {
      this.$router.push({
        path: "/yunwei/yunwei/createWorkOrder",
        query: { listItem, index },
      });
    },
    getTableList() {
      let params = {
        //   condition: {
        //     powerStationCode: this.powerStationCode,
        //     powerStationName: this.powerStationName
        //   },
        currPage: 1,
        pageSize: 100000,
      };
      this.$http.post("WorkOrder/workList", params).then((res) => {
        this.showlist = res.data.data.data;
      });
    },
    deleteClick(item) {
      console.log(item,212);
      this.$confirm(`确定删除${item.workOrderName}吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("WorkOrder/deleteWork", {
              id:item.id,
              powerStationCode: this.powerStationCode,
              powerStationName: this.powerStationName,
            })
            .then((res) => {
              if (res.data.code === 200) {
                this.$notify.success({
                  title: "提示",
                  message: "删除成功",
                  showClose: true,
                });
                this.getTableList();
              }
            });
          // this.$message({
          //   type: "success",
          //   message: "删除成功!",
          // });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  background: #223f6c;
  // width: 100%;
  height: 100%;
  padding: 10px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  .flex-class {
    display: flex;
    justify-content: flex-end;
    margin: 10px 0;
  }

  /deep/ .el-input__inner {
    border-radius: 5px;
    border: 1px solid rgba(151, 179, 203, 0.55);
    background-color: rgba(255, 255, 255, 0);
    box-sizing: border-box;
    font-family: "Arial", sans-serif;
    color: #f2f2f2;
    text-align: left;
    line-height: normal;
  }

  .home-class {
    height: 80vh;
    flex-grow: 1;
    border-radius: 5px;
    border: 1px solid rgba(151, 179, 203, 0.61);
    background-color: #21527e;
    box-sizing: border-box;

    .head-class {
      height: 64px;
      border-bottom: 1px solid rgba(151, 179, 203, 0.61);
      background-color: #21527e;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding-left: 30px;

      .title-class {
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #ffffff;
        font-size: 18px;
      }
    }

    .list-class {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px 20px 0 31px;

      .line-class-left {
        display: flex;
        align-items: center;
        width: 200px;

        .image-border {
          width: 40px;
          height: 40px;
          box-sizing: border-box;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 25px;
            height: 25px;
          }
        }

        .line-left {
          .line-left-title {
            height: 23px;
            box-sizing: border-box;
            font-family: "微软雅黑", sans-serif;
            color: #ffffff;
            text-align: left;
            line-height: normal;
            margin-left: 6px;
            font-size: 15px;
          }

          .line-left-text {
            height: 16px;
            box-sizing: border-box;
            font-family: "微软雅黑", sans-serif;
            color: #d7d7d7;
            text-align: left;
            line-height: normal;
            margin-left: 6px;
            font-size: 13px;
          }
        }
      }

      .line-class-content {
        background-color: rgba(255, 255, 255, 0);
        box-sizing: border-box;
        font-family: "微软雅黑", sans-serif;
        color: #f2f2f2;
        text-align: left;
        line-height: normal;
        font-size: 16px;
      }

      .line-class-right {
        display: flex;
        align-items: center;

        img {
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          margin-left: 20px;
        }
      }
    }
  }
}

.listbox {
  width: 100%;
  text-align: center;
  color: #fff;
  padding-top: 100px;
  box-sizing: border-box;
}

/deep/ .el-button--primary.is-plain {
  color: #fff;
  background: #21527e;
  border-color: #6b8297;
}
</style>
